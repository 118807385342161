.zmw-Columns {
  @include media('>large') {
    @include content-container--wide;
    @include clearfix;

    display: flex;
    justify-content: space-between;
    margin-bottom: zmw-spacing(xlarge);
  }
}

/**
 * 1 - Not 50% so it also works if flex isn't supported
 */
.zmw-Columns_Column {
  @include media('<=large') {
    @include container;
  }

  @include media('>large') {
    float: left;
    width: 48%; /* 1 */
    @supports (display: flex) {
      width: 50%;
    }
  }
}

// Remove margin if there is only one element
.zmw-Columns_Column > div:nth-child(1):last-child,
.zmw-Columns_Column > article:nth-child(1):last-child {
  @include media('>large') {
    margin: 0;
  }
}

.zmw-Columns .zmw-ContentSection_Container {
  @include media('>large') {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

/**
 * 1 - In order to fill all space in Columns block if there is only one ContentSection
 */
.zmw-Columns_Column > .zmw-ContentSection:nth-child(1):last-child,
.zmw-Columns_Column > .zmw-ContentSection:nth-child(1):last-child .zmw-ContentSection_Container {
  @include media('>large') {
    height: 100%; /* 1 */
  }
}

/**
 * 1 - Spacing if flex isn't supported
 */
.zmw-Columns_Column:not(:first-child) {
  @include media('>large') {
    margin-left: zmw-spacing(xsmall); /* 1 */
  }
}
