/// Helper for hiding content visually but not from screenreaders
/// @group Layout
@mixin visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  width: 1px;
}

@mixin visually-hidden--cancel {
  clip: initial;
  height: auto;
  margin: auto;
  opacity: 1;
  padding: initial;
  width: auto;
}
