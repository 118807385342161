.zmw-AsyncForm_Container {
  @include content-container;

  background: white;
  padding: zmw-spacing(large) zmw-spacing(medium) zmw-spacing(medium);
}

.zmw-AsyncForm_Title {
  @include as-h3-brand;
}
