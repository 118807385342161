.ce-SocialMediaStream_Container {
  max-width: 1400px;
  margin: 0 auto;
  background: transparent !important;
}

.sa-SocialMediaAggregator_Container {
  .sa-SocialMediaAggregator_Description {
    font-size: zmw-fontsize(small);
    font-weight: zmw-fontweight(light);
  }
}

.lm-LoadingMessage_Container {
  display: none;

  .lm-LoadingMessage_Text {
    margin-top: 0.5rem;
    color: zmw-color(brand);
    font-weight: zmw-fontweight(light);
  }
}

.ce-SocialMediaStream_LoadMore {
  @include zmw-button;

  font-weight: zmw-fontweight(medium);
  max-width: 399px;
  margin: 0 auto;
  text-align: center;

  &[aria-hidden="true"] {
    display: none;
  }
}

.lm-LoadingMessage_Container .lm-LoadingMessage_Spinner [class*=lm-LoadingMessage_Bouncer-] {
  background: zmw-color(brand) !important;
}
