.zmw-BrandedCallToAction {
  @include content-container;

  margin-bottom: zmw-spacing(xxlarge);
}

/*
 * 1 - Keep a readable line length
 */
.zmw-BrandedCallToAction_Body {
  display: block;
  display: flex;
  align-items: center;
  background-color: zmw-color(base);
  transition: opacity zmw-transition(base);
  box-shadow: zmw-boxshadow(light);
  border-radius: $zmw-button-radius;
  max-width: 25em; /* 1 */

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.9;
  }
}

.zmw-BrandedCallToAction_Media {
  float: left;
  width: 50%;
  max-width: 150px;
  margin-right: zmw-spacing(medium);
  padding: zmw-spacing(medium) zmw-spacing(small);
  border-radius: $zmw-button-radius 0 0 $zmw-button-radius;
}

/*
 * 1 - Remove extra space below img
 */
.zmw-BrandedCallToAction_Logo {
  max-width: 100%;
  display: block; /* 1 */
}

/*
 * 1 - Temporary until branded call to action is live on contentful
 */
.zmw-BrandedCallToAction_Label {
  @include link-arrow;

  color: zmw-color(base, text-default); /* 1 */
}
