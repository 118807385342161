/* regular */
@font-face {
  font-family: 'Gibson';
  font-weight: 400;
  font-style: normal;
  src: url('/assets/fonts/gibson/gibson-regular-webfont.woff') format('woff'),
    url('/assets/fonts/gibson/gibson-regular-webfont.woff2') format('woff2');
}
/* italic */
@font-face {
  font-family: 'Gibson';
  font-weight: 400;
  font-style: italic;
  src: url('/assets/fonts/gibson/gibson-italic-webfont.woff') format('woff'),
    url('/assets/fonts/gibson/gibson-italic-webfont.woff2') format('woff2');
}
/* light */
@font-face {
  font-family: 'Gibson';
  font-weight: 300;
  font-style: normal;
  src: url('/assets/fonts/gibson/gibson-light-webfont.woff') format('woff'),
    url('/assets/fonts/gibson/gibson-light-webfont.woff2') format('woff2');
}
/* lightitalic */
@font-face {
  font-family: 'Gibson';
  font-weight: 300;
  font-style: italic;
  src: url('/assets/fonts/gibson/gibson-lightitalic-webfont.woff')
      format('woff'),
    url('/assets/fonts/gibson/gibson-lightitalic-webfont.woff2') format('woff2');
}
/* medium */
@font-face {
  font-family: 'Gibson';
  font-weight: 500;
  font-style: normal;
  src: url('/assets/fonts/gibson/gibson-medium-webfont.woff') format('woff'),
    url('/assets/fonts/gibson/gibson-medium-webfont.woff2') format('woff2');
}
/* mediumitalic */
@font-face {
  font-family: 'Gibson';
  font-weight: 500;
  font-style: italic;
  src: url('/assets/fonts/gibson/gibson-mediumitalic-webfont.woff')
      format('woff'),
    url('/assets/fonts/gibson/gibson-mediumitalic-webfont.woff2')
      format('woff2');
}
/* semibold */
@font-face {
  font-family: 'Gibson';
  font-weight: 600;
  font-style: normal;
  src: url('/assets/fonts/gibson/gibson-semibold-webfont.woff') format('woff'),
    url('/assets/fonts/gibson/gibson-semibold-webfont.woff2') format('woff2');
}
/* semibold italic */
@font-face {
  font-family: 'Gibson';
  font-weight: 600;
  font-style: italic;
  src: url('/assets/fonts/gibson/gibson-semibolditalic-webfont.woff')
      format('woff'),
    url('/assets/fonts/gibson/gibson-semibolditalic-webfont.woff2')
      format('woff2');
}

body {
  font-family: zmw-fontstack(subset);
  font-size: zmw-fontsize(base, 'px');
  font-weight: zmw-fontweight(light);
  color: zmw-color(base, text-default);
  -webkit-font-smoothing: antialiased;

  @include media('>medium') {
    font-size: zmw-fontsize(medium, 'px');
  }

  @include media('>large') {
    font-size: zmw-fontsize(large, 'px');
  }
}

.fonts-stage-1 body {
  font-family: zmw-fontstack(base);
}

h1 {
  @include as-h1;
}

h2 {
  @include as-h2;
}

// TODO: Add hover, focus and active
a {
  color: zmw-color(base-grey, text-default);
  text-decoration: none;
  font-weight: zmw-fontweight(medium);
}

strong {
  font-weight: zmw-fontweight(medium);
}

p {
  line-height: 1.5;
}
