.zmw-ContentSection {
  @include content-container;

  margin-bottom: zmw-spacing(xlarge);
}

/**
 * 1 - It should takes all width in a ContentSection
 */
.zmw-ContentSection_Container > .zmw-CallToAction {
  width: 100%; /* 1 */
}

.zmw-ContentSection_Container > .zmw-CallToAction .zmw-CallToAction_Link {
  @include media('>medium') {
    width: 100%;
  }
}

// Remove margin if there is only one element
.zmw-ContentSection .zmw-ContentSection_Container > div:nth-child(1):last-child,
.zmw-ContentSection .zmw-ContentSection_Container > article:nth-child(1):last-child {
  margin: 0;
}

.zmw-ContentSection:not(.zmw-ContentSection-informational) .zmw-ContentSection_Container {
  @include block-spacing;

  background-color: zmw-color(base);
}

.zmw-ContentSection-informational {
  margin-top: zmw-spacing(xxxlarge);
  margin-bottom: zmw-spacing(xxxlarge);
}

.zmw-ContentSection-informational .zmw-ContentSection_Container {
  @include block-spacing;

  background-color: zmw-color(base-beige);
  color: zmw-color(base-beige, text-default);
}
