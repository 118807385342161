@mixin cta-small {
  @include link-arrow;

  display: block;
  font-size: zmw-fontsize(base);
  color: zmw-color(base);
  width: 100%;
  padding: zmw-spacing(xsmall);
  background-color: zmw-color(base-grey, text-default);
  text-align: center;
  box-shadow: zmw-boxshadow(light);
  border-radius: $zmw-button-radius;
  transition: background-color zmw-transition(base);
  border: 1px solid transparent;

  &:hover,
  &:focus,
  &:active {
    background-color: zmw-color(base);
    color: zmw-color(brand);
    border: 1px solid zmw-color(brand);
  }
}

@mixin cta-bold {
  @include link-arrow;

  display: block;
  font-size: zmw-fontsize(large);
  color: zmw-color(base);
  width: 100%;
  padding: 1em 0;
  background-color: zmw-color(brand);
  text-align: center;
  box-shadow: zmw-boxshadow(light);
  border-radius: $zmw-button-radius;
  transition: background-color zmw-transition(base);
  border: 1px solid transparent;

  &:hover,
  &:focus,
  &:active {
    background-color: zmw-color(base);
    color: zmw-color(brand);
    border: 1px solid zmw-color(brand);
  }
}

/*
 * 1 - Prevent underline on after element on hover
 */
@mixin cta-muted {
  text-align: right;
  width: auto;
  font-size: zmw-fontsize(base);
  color: zmw-color(base-grey, text-default);
  background-color: transparent;
  padding: 0;
  padding-right: 0.8em; /* 1 */
  box-shadow: none;
  position: relative; /* 1 */

  &::after {
    position: absolute; /* 1 */
    right: 0;
    top: 0; /* 1 */
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}
