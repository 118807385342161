.srk-SliderGallery_Description {
  padding: zmw-spacing(small) zmw-spacing(small) zmw-spacing(small) zmw-spacing(small);
  overflow: auto;
}

.srk-SliderGallery_Text {
  padding-top: zmw-spacing(xsmall);
}

.srk-SliderGallery [class*='-button'] {
  width: zmw-fontsize(xxlarge);
  height: zmw-fontsize(xxlarge);
  position: absolute;
  top: 33.3333%;
  border: none;
  opacity: 0.95;

  & svg {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.srk-srk-SliderGallery_Video,
.srk-srk-SliderGallery_Image {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
  height: 0;
  overflow: hidden;
}

.srk-srk-SliderGallery_Video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.srk-srk-SliderGallery_Image img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
}
