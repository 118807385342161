.zmw-Quote {
  @include content-container;

  margin-bottom: zmw-spacing(small);
}

.zmw-Quote_Body {
  @include block-spacing;
}

.zmw-Quote_Content {
  font-size: zmw-fontsize(medium);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(brand);
  line-height: 1.3;

  &::before {
    content: "«";
  }

  &::after {
    content: "»";
  }
}

.zmw-Quote_Attribution {
  text-align: right;
  margin-top: zmw-spacing(small);

  &::before {
    content: '–';
    margin-right: zmw-spacing(xxsmall);
  }
}
