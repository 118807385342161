.srk-SliderGallery {
  @include content-container;

  padding: 0 !important;
  position: relative;
}

.srk-SliderGallery_Loading {
  padding: zmw-spacing(large);
  text-align: center;
}

.srk-SliderGallery_Title {
  @include media('<large') {
    padding-left: zmw-spacing(large);
    padding-right: zmw-spacing(large);
  }
  @include media('<medium') {
    padding-left: zmw-spacing(medium);
    padding-right: zmw-spacing(medium);
  }
}

.srk-SliderGallery_Container {
  margin-top: zmw-spacing(small);
  background: zmw-color(base);
}

.srk-SliderGallery_Container .carousel__inner-slide {
  display: flex;
  flex-direction: column;
}

.srk-SliderGallery [class*='-button'] {
  width: zmw-fontsize(xxlarge);
  height: zmw-fontsize(xxlarge);
  position: absolute;
  top: 33.3333%;
  border: none;
  opacity: 0.95;

  & svg {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.srk-SliderGallery .carousel__back-button {
  left: zmw-spacing(small);
}

.srk-SliderGallery .carousel__next-button {
  right: zmw-spacing(small);
}

.srk-SliderGallery .carousel__dot-group {
  display: flex;
  justify-content: center;
  padding: zmw-spacing(small) 0;
}

.srk-SliderGallery .carousel__dot {
  border: none;
  min-width: zmw-spacing(xlarge);
  height: 4px;
  margin-right: zmw-spacing(xsmall);
  background: zmw-color(base-grey, text-dimmed);
}

.srk-SliderGallery .carousel__dot--selected {
  background: zmw-color(brand);
}
