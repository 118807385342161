$logo-height: 35px;
$logo-height-wide: 40px;

.zmw-MainMenu {
  background-color: zmw-color(base);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  padding: zmw-spacing(xxsmall) 0 zmw-spacing(xsmall);
  @include media('>large') {
    padding: zmw-spacing(xsmall) 0 zmw-spacing(small);
  }
}

.zmw-MainMenu_Container {
  @include clearfix;
  @include container--wide;
  display: flex;
  align-items: center;
  position: relative;
  padding:
    zmw-spacing(xsmall)
    zmw-spacing(xsmall)
    zmw-spacing(xxsmall)
    zmw-spacing(xsmall);

    @include media('>large') {
      padding:
      zmw-spacing(xxsmall)
      zmw-spacing(xxsmall)
      zmw-spacing(xxsmall)
      zmw-spacing(xxsmall);
    }
}

.zmw-MainMenu_LogoLink {
  font-size: 0;
  height: $logo-height;
  display: inline-block;
  vertical-align: middle;
  height: 45px;
  @include media('>large') {
    height: 65px;
  }

}

.zmw-MainMenu_LogoLink .zmw-Logo {
  height: 100%;
}

/**
 * 1. In order to have button and logo on same line
 */
.zmw-MainMenu_Body {
  float: left;

  @include media('>large') {
    display: flex;
    flex: 1;
  }
}

.zmw-MainMenu_Buttons {
  margin: 0 0 0 auto;
}

/**
 * 1. Hide the aside (for inline languageMenu) on mobile, show it on >large
 */
.zmw-MainMenu .zmw-MainMenu_Aside {
  display: none; /* 1 */

  @include media('>large') {
    display: block; /* 1 */
  }
}

/**
 * 1. Ajust div baseline
 */
.zmw-MainMenu_Button {
  background-color: transparent;
  border: 2px solid zmw-color(base-grey, least-contrast);
  padding: zmw-spacing(small);
  border-radius: 4px;
  font-size: zmw-fontsize(small); /* 1 */
  line-height: 1; /* 1 */
  cursor: pointer;
  float: right;

  @include media('>large') {
    display: none;
  }
}

.zmw-MainMenu_Label {
  font-weight: zmw-fontweight(medium);
  color: zmw-color(base-grey, text-default);
  text-transform: uppercase;
}

/**
 * 2. Parenting absolutely positioned icon lines
 */
.zmw-MainMenu_Icon {
  display: inline-block;
  height: 1em;
  position: relative; /* 2 */
  width: 20px; /* 1 */
  vertical-align: middle;
  margin-left: zmw-spacing(small);
}

/**
 * 1. Two of the lines will rotate on active
 * 2. Shared with the color change on text for consistent feel
 */
.zmw-MainMenu_IconLine {
  background-color: zmw-color(base-grey, text-default);
  display: block;
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}

/**
 * 1. Minus half of the line's height, which is known
 */
.zmw-MainMenu_IconLine:nth-child(2) {
  top: calc(50% - 1px); /* 1 */
}

.zmw-MainMenu_IconLine:nth-child(3) {
  bottom: 0;
}

.js-zmw-MainMenu--isOpen .zmw-MainMenu_Button {
  border-color: zmw-color(brand);
}

.zmw-MainMenu_Nav {
  @include clearfix;

  position: absolute;
  background: zmw-color(base);
  width: 100%;
  top: 100%;
  right: 0;
  z-index: 15;
  height: 0;
  overflow-x: scroll;

  @include media('>large') {
    width: auto;
    background: none;
  }
}
.js-zmw-MainMenu--isOpen {
  position: fixed;
}
.js-zmw-MainMenu--isOpen .zmw-MainMenu_Nav {
  height: 100vh;
  padding-bottom: 10em;
}

.zmw-MainMenu_NavList {
  margin-top: 0.25em;
}

.zmw-MainMenu_Nav .zmw-MainMenu_Link,
.zmw-MainMenu_Nav .zmw-LanguageMenu_Link {
  display: block;

  &:hover,
  &:focus {
    background: zmw-color(base-grey);
  }
}

.zmw-MainMenu_Nav .zmw-MainMenu_Link {
  display: block;
  font-size: zmw-fontsize(medium);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(base, text-default);
  padding: zmw-spacing(medium) zmw-spacing(xlarge);
}

.zmw-MainMenu_Page.active .zmw-MainMenu_Link,
.zmw-LanguageMenu_Link.active {
  color: zmw-color(brand);
}

.zmw-MainMenu_Link, .zmw-LanguageMenu_Link {
  &:hover {
    color: zmw-color(brand);
  }
}

.zmw-MainMenu_Aside .zmw-LanguageMenu_Link {
  display: inline-block;
  height: $logo-height-wide;
  line-height: $logo-height-wide;
  font-size: zmw-fontsize(small);
  padding: 0 0.2rem;
}

.zmw-MainMenu_Nav .zmw-LanguageMenu_Link {
  color: zmw-color(base, text-default);
  padding: zmw-spacing(small) zmw-spacing(xlarge);
}

.zmw-LanguageMenu,
.zmw-MainMenu_NavList {
  list-style-type: none;
}

.zmw-LanguageMenu {
  margin-top: 0.4rem;
}

/*****
Style for desktop inlined menu
*****/

.zmw-MainMenu_InlineNav {
  display: none;
  vertical-align: middle;

  @include media('>large') {
    display: flex;
    margin: 0 auto;
  }
}

.zmw-MainMenu_InlineNav .zmw-MainMenu_NavList {
  padding: 0;
}

/*
 * Space between list item when this list has multiple lines
 */
.zmw-MainMenu_InlineNav .zmw-MainMenu_Page {
  display: inline-block;
  margin-right: zmw-spacing(small);
  line-height: 2; /* 1 */
  &:last-child {
    margin-right: 0;
  }
}

.zmw-MainMenu_Button-locale {
  display: none;

  @include media('>large') {
    display: block;
  }
}

.zmw-MainMenu + {
  .zmw-BrandedCallToAction,
  .zmw-CallToAction,
  .zmw-Columns,
  .zmw-ContentBlock,
  .zmw-ContentSection,
  .zmw-CorporatePartnerGroup,
  .zmw-Form,
  .zmw-QuestionGroup,
  .zmw-Story,
  .zmw-Timeline {
    margin-top: zmw-spacing(large);
  }
}
