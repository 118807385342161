.zmw-SupportForm {
  @include content-container;
}

.zmw-SupportForm_Button {
  margin-left: auto;
  margin-right: auto;
  margin-top: zmw-spacing(medium);
  display: block;

  @include media('>medium') {
    margin-left: 0;
  }
}

/**
 * 1. To contain label
 */
.zmw-SupportForm .zmw-InputText {
  margin-top: zmw-spacing(medium);
  position: relative; /* 1 */
  padding-top: zmw-spacing(small); /* 1 */

  @include media('>medium') {
    width: 50%;
  }
}

/**
 * 1. Leave some space before textarea for label
 * 2. To contain label
 */
.zmw-SupportForm .zmw-TextArea {
  margin-top: zmw-spacing(medium);
  padding-top: zmw-spacing(medium); /* 1 */
  position: relative;

  @include media('>medium') {
    width: 70%;
  }
}

/**
 * 1. Prepare transition for label text to be shown when input placeholder text is not visible
 * 2. Changed to static number so it works for every containers (variable height)
 */
.zmw-SupportForm .zmw-InputText_Label {
  @include visually-hidden;

  left: 0; /* 1 */
  top: 20px; /* 1 *//* 2 */
  transition: opacity 0.2s ease-in, top 0.1s ease-in; /* 1 */
}

/**
 * 1. This is already positioned absolutely, but repeat for robustness if visually-hidden mixin changes
 * 2. Pull up above the input field, on an assumption that space is reserved in row container
 */
.zmw-SupportForm .zmw-InputText_Input:not(:placeholder-shown) + .zmw-InputText_Label,
.zmw-SupportForm .zmw-TextArea_Input:not(:placeholder-shown) + .zmw-InputText_Label {
  @include visually-hidden--cancel;

  position: absolute; /* 1 */
  top: 0; /* 2 */
}
