/*
 * 1 - To be below timeline
 */
.zmw-Timeline {
  @include content-container;

  position: relative;
  height: 100%;
  margin-bottom: zmw-spacing(xlarge);

  &::before {
    content: '';
    width: 3px;
    height: 100%;
    display: block;
    position: absolute;
    left: 50%;
    z-index: -1; /* 1 */
    transform: translateX(-50%);
    background-color: zmw-color(base);
  }

  &::after {
    content: '';
    width: 0.8em;
    height: 0.8em;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    background-color: zmw-color(base);
  }
}

.zmw-Timeline .zmw-Quote_Body {
  background-color: zmw-color(base);
}

/*
 * Hide line behind ContentBlock
 */
.zmw-Timeline > .zmw-ContentBlock {
  background-color: zmw-color(base-grey);
  padding-top: zmw-spacing(small);
  padding-bottom: zmw-spacing(small);
}

/*
 * Margin before each 'year' block (except first element)
 */
.zmw-Timeline > .zmw-YearMarker ~ .zmw-YearMarker {
  margin-top: zmw-spacing(xxlarge);
}

.zmw-Timeline > {
  .zmw-Quote,
  .zmw-ContentBlock,
  .zmw-ContentSection {
    margin-bottom: zmw-spacing(large);

    @include media('>medium') {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
