.zmw-InputText_Label {
  @include as-h3;
}

.zmw-InputText_Input {
  @include input-text-layout;
  @include input-text-border;

  margin-top: zmw-spacing(xsmall);
}

.zmw-InputText--error .zmw-InputText_Input {
  border-color: zmw-color(error);
}
