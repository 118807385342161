.zmw-YearMarker {
  font-size: zmw-fontsize(xxlarge);
  color: zmw-color(base-grey, text-default);
  text-align: center;
  display: block;
  font-weight: zmw-fontweight(medium);
  background-color: zmw-color(base-grey);
  padding: zmw-spacing(small);
  margin-bottom: zmw-spacing(small);
}
