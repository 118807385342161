.zmw-QA {
  margin-bottom: zmw-spacing(large);
}

.zmw-QA_Question {
  font-weight: zmw-fontweight(medium);
  position: relative;
  padding-left: zmw-spacing(large);
  cursor: pointer;

  &::before {
    position: absolute;
    top: 0%;
    left: 0;
    content: '>';
    width: zmw-spacing(medium);
    height: zmw-spacing(medium);
    line-height: zmw-spacing(medium);
    background: zmw-color(brand);
    text-align: center;
    color: zmw-color(base);
    border-radius: $zmw-button-radius;
    transition: transform zmw-transition(base);
  }
}

.zmw-QA.visible .zmw-QA_Question::before {
  transform: rotate(90deg);
  transition: transform zmw-transition(base);
}

.zmw-QA_Answer {
  margin-top: zmw-spacing(xsmall);
  padding-left: zmw-spacing(large);
  max-height: 0;
  transition: max-height zmw-transition(base);
  overflow: hidden;

  a {
    color: zmw-color(brand);
  }
}

.zmw-QA.visible .zmw-QA_Answer {
  max-height: fit-content;
  transition: max-height zmw-transition(base);
}
