@mixin as-h1 {
  font-size: zmw-fontsize(xlarge);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(brand);
  line-height: 1.2;

  @include media('>large') {
    font-size: zmw-fontsize(xxlarge);
  }
}

@mixin as-h2 {
  font-size: zmw-fontsize(large);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(brand);
  line-height: 1.2;

  @include media('>large') {
    font-size: zmw-fontsize(xlarge);
  }
}

@mixin as-h2-dark {
  font-size: zmw-fontsize(large);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(base-grey, text-default);
  line-height: 1.2;

  @include media('>large') {
    font-size: zmw-fontsize(xlarge);
  }
}

// TODO: Maybe it should has a more specific name (it is currently for section header in donation widget)
@mixin as-h3 {
  font-size: zmw-fontsize(base);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(base-grey, text-default);
  text-transform: uppercase;
}

@mixin as-h3-dark {
  font-size: zmw-fontsize(base);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(base-grey, text-default);
  text-transform: uppercase;
}

@mixin as-h3-brand {
  font-size: zmw-fontsize(large);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(brand);
}
