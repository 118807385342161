.sr-Player {
  position: relative;
  padding-bottom: 56.12%;

  iframe {
    position: absolute;
  }

  & + p {
    margin-top: 1em;
  }
}