/*
 * 1 - Fallback for calc
 * 2 - Magic number: Force min height on small screens
 */
.dw-DonationItem {
  position: relative;
  min-height: 12rem; /* 2 */
  display: inline-block;

  &.dw-DonationItem-active {
    .dw-Filpper_Content {
      .dw-Filpper_Front {
        transform: rotateY(180deg);
      }

      .dw-Filpper_Back {
        transform: rotateY(0deg);
      }
    }
  }
}

.dw-DonationItem_Content {
  text-align: center;
  position: relative;
  padding: zmw-spacing(xxsmall) 0.125rem;
  margin: 0 zmw-spacing(xsmall);
  background: zmw-color(base);
  box-shadow: zmw-boxshadow(medium);
  height: 100%;

  .dw-Filpper_Container {
    margin: (0 - zmw-spacing(xxsmall)) (0 - zmw-spacing(xxsmall)) 0
      (0 - zmw-spacing(xxsmall));
    min-height: 149px;
    overflow: hidden;
  }
}

/*
 * 1 - Leaves some space for DonationItem meta
 */
.dw-DonationItem_Image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.dw-DonationItem_Title {
  font-size: zmw-fontsize(small);
  font-weight: zmw-fontweight(light);
  color: zmw-color(base-grey, text-default);
}

.dw-DonationItem_Price {
  font-size: zmw-fontsize(medium);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(base, text-default);
}

.dw-DonationItem_Currency {
  font-size: zmw-fontsize(xsmall);
}

.dw-DonationItem_Meta {
  padding: zmw-spacing(xxsmall) 0 0 0;
}

.dw-DonationItem_Actions {
  position: absolute;
  top: 118px;
  width: 100%;
  z-index: 5;
  font-weight: zmw-fontweight(light);
  display: flex;
  justify-content: center;
  align-items: center;

  .dw-DonationItem_ButtonContainer {
    padding: 0 zmw-spacing(xxsmall);

    .dw-Button {
      padding: 0;
    }
  }
}

/*
 * 1. Draw a precise plus sign
 */
.dw-DonationItem_Increase {
  width: 30px;
  height: 30px;
  text-indent: -99999px; /* 1 */
  position: relative; /* 1 */

  &::before,
  &::after {
    content: ''; /* Empty content for pseudo-elements */
    position: absolute;
    top: 50%;
    left: 50%;
    background: zmw-color(base);
  }

  &::before {
    width: 4px;
    height: 18px;
    margin: -9px 0 0 -2px;
  }

  &::after {
    height: 4px;
    width: 18px;
    margin: -2px 0 0 -9px;
  }
}

.dw-DonationItem_Amount {
  font-family: zmw-fontstack(ui);
  font-weight: zmw-fontweight(semi);
  font-size: 23px;
  line-height: 1;
  width: 30px;
  height: 30px;
  background: zmw-color(base-grey, text-default);
  border-color: zmw-color(base-grey, text-default);
}

/*
 * 1. Smaller than default rounded button
 * 2. Draw a precise minus sign
 */
.dw-DonationItem_Decrease {
  font-size: 30px; /* 1 */
  width: 30px;
  height: 30px;
  line-height: 30px; /* 1 */
  text-indent: -99999px; /* 2 */
  position: relative; /* 2 */

  &::after {
    content: ''; /* 2 */
    position: absolute;
    top: 50%;
    left: 50%;
    background: zmw-color(base);
    height: 4px;
    width: 16px;
    margin: -2px 0 0 -8px;
  }
}

.dw-DonationItem_Increase,
.dw-DonationItem_Decrease {
  &:focus,
  &:active {
    border-color: transparent;
    background-color: zmw-color(base-grey, less-contrast);
  }

  &:hover {
    border-color: transparent;
    background-color: zmw-color(base-grey, text-dimmed) !important;
  }

  &:hover::before,
  &:hover::after {
    background: zmw-color(base) !important;
  }

  &:active::before,
  &:active::after,
  &:focus::before,
  &:focus::after {
    background: zmw-color(base, text-emphasized);
  }
}

.dw-DonationItem_Decrease,
.dw-DonationItem_Amount {
  display: none;
}

.dw-DonationItem_Description {
  position: absolute;
  bottom: 0;
  padding: zmw-spacing(xsmall) zmw-spacing(xsmall);
  font-size: zmw-fontsize(small);
  color: zmw-color(base);
}

.dw-DonationItem_IllustrationWrapper {
  position: absolute;
  top: 0;
  height: 100%;
  padding: zmw-spacing(xxsmall);
}

.dw-DonationItem_Illustration {
  display: block;
  width: 100%;
}

.dw-DonationItem-active .dw-DonationItem_Decrease,
.dw-DonationItem-active .dw-DonationItem_Amount {
  display: inline-block;
}

.dw-DonationItem-active .dw-DonationItem_Details {
  display: block;
}

.dw-DonationItem-active .dw-DonationItem_IllustrationWrapper {
  @include flip;
}
