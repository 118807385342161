.zmw-RaiseNow {
  background-color: zmw-color(base);
}

/*
 * Hide other content types on checkout/thank-you page
 *
 * 1 - .zmw-ThankYouSharings are handled separately since display:none prevents
 *     share links from being rendered properly on some browsers
 */
.js-on-checkout > .zmw-BrandedCallToAction,
.js-on-checkout > .zmw-CallToAction,
.js-on-checkout > .zmw-Columns,
.js-on-checkout > .zmw-ContentBlock,
.js-on-checkout > .zmw-ContentSection,
.js-on-checkout > .zmw-CorporatePartnerGroup,
.js-on-checkout > .zmw-HeaderBlock,
.js-on-checkout > .zmw-Form,
.js-on-checkout > .zmw-QuestionGroup,
.js-on-checkout > .zmw-Story,
.js-on-checkout > .zmw-Timeline,
.js-on-checkout > .zmw-MainMenu,
.js-on-checkout > footer {
  display: none;
}

.js-on-thank-you > .zmw-CallToAction,
.js-on-thank-you > .zmw-Columns,
.js-on-thank-you > .zmw-ContentBlock,
.js-on-thank-you > .zmw-ContentSection:not(.zmw-ThankYouSharings), /* 1 */
.js-on-thank-you > .zmw-CorporatePartnerGroup,
.js-on-thank-you > .zmw-HeaderBlock,
.js-on-thank-you > .zmw-Form,
.js-on-thank-you > .zmw-QuestionGroup,
.js-on-thank-you > .zmw-Story,
.js-on-thank-you > .zmw-Timeline,
.js-on-thank-you > .zmw-BrandedCallToAction {
  display: none;
}

.zmw-RaiseNow_Container {
  @include container;

  padding-top: zmw-spacing(medium);
  padding-bottom: zmw-spacing(medium);
}

.zmw-RaiseNow_Container .lds-ring {
  margin-top: 1em;
}

.zmw-RaiseNow_Amount {
  @include as-h2;
  position: sticky;
  top: 0;
  padding: 0.25em 0;
  background: white;
  z-index: 15;
}

/************************
* These lines below overwrite RaiseNow default style
************************/

:root {
  --tamaro-primary-color: #{zmw-color(brand)};
  --tamaro-button-primary-bg-color__hover: #{zmw-color(base)};
  --tamaro-button-primary-color__hover: #{zmw-color(brand)};
  --tamaro-button-primary-border-color__hover: #{zmw-color(brand)};
  --tamaro-primary-color__hover: #{zmw-color(brand)};
  --tamaro-text-color__hover: #{zmw-color(brand)};
  --tamaro-label-color: #{zmw-color(base, text-default)};
  --tamaro-text-color: #{zmw-color(base, text-default)};
}

[class='paymentForm'] {
  & > {
    #tamaro-widget.tamaro-widget {
      .widget-content {
        margin-top: 1.75em;
        .widget-block {
          .widget-block-header {
            display: block;
            .title {
              .text {
                @include as-h3-brand;
              }
            }
          }
          .widget-block-content {
            .stored_extra_donation {
              margin-top: 2em;
            }
          }
        }
      }
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid zmw-color(brand);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: zmw-color(brand) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


[class='dds-widget-container'] {
  padding: 0;

  & > {
    #lema-container.lema-container {
      font-family: zmw-fontstack(base);
      line-height: inherit;
      max-width: none;

      .font-helvetica,
      .select2-results li,
      .lema-accordion-item .lema-accordion-header,
      .lema-tabs li a,
      .lema-loading-text,
      .lema-overlay-header,
      .lema-overlay-footer,
      .lema-overlay-content,
      .lema-timer-countdown,
      .lema-timer-info,
      .lema-linklist,
      .lema-button,
      .lema-error-messages label,
      .lema-error-label,
      .lema-content,
      .lema-step-row-text,
      .lema-step-header-text,
      .lema-step-number,
      .lema-popup-content,
      .lema-member-add,
      .lema-member-delete-overlay,
      .lema-pseudo-label,
      .lema-radio-label,
      .lema-checkbox-label,
      textarea.lema-textarea,
      .lema-summary-check,
      .lema-security,
      .select2-container.select2-container .select2-choice,
      .lema-tabs li a,
      .lema-pseudo-label,
      .lema-info-icon {
        font-family: zmw-fontstack(base);
        letter-spacing: 0;
      }

      .lema-input-text,
      .lema-input-textarea {
        font-family: zmw-fontstack(base);
        letter-spacing: 0;
        @include placeholder {
          font-family: zmw-fontstack(base);
          letter-spacing: 0;
        }
      }

      .lema-step-header {
        border: none;
      }

      .lema-step-number-text {
        background: zmw-color(brand);
      }

      .lema-step-content {
        background: zmw-color(base-grey);
        border: none;
      }

      .lema-content.lema-accordion .lema-step-content {
        padding: zmw-spacing(small);
      }

      .lema-button {
        background: zmw-color(brand);
        border: 1px solid transparent;

        &:hover,
        &:active {
          background-color: zmw-color(base);
          color: zmw-color(brand);
          border: 1px solid zmw-color(brand);
        }
      }
    }
  }
}

/*
 * Fix the font in the crazy dropdown
 */
.select2-drop {
  .select2-results li {
    font-family: zmw-fontstack(base);
  }
}
