.zmw-Button {
  @include zmw-button;
}

/*
 * Donation-widget buttons
 */
.dw-Button {
  @include zmw-button;

  border-radius: 0;
  display: inline-block;
}

.dw-Button-large {
  padding: 1em 4em;

  @include media('>medium') {
    padding: 1em 0.5em;
  }
}

.dw-Button-active {
  background: zmw-color(brand,);
  border: 1px solid transparent;
  &:hover,
  &:focus {
    background-color: zmw-color(base);
    color: zmw-color(brand);
    border: 1px solid zmw-color(brand);
  }
}

.dw-Button-disabled {
  color: zmw-color(brand, text-default);
  background-color: zmw-color(base-grey, text-dimmed);
  &:hover {
    background-color: zmw-color(base-grey, less-contrast) !important;
    color: zmw-color(base-grey, text-dimmed) !important;
    border: 1px solid transparent;
  }
  &:focus {
    color: zmw-color(brand, text-default);
    background-color: zmw-color(base-grey, text-dimmed);
    border: 1px solid transparent;
  }
}
