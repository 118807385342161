@mixin container {
  margin-left: auto;
  margin-right: auto;
  padding-left: zmw-spacing(medium);
  padding-right: zmw-spacing(medium);
  max-width: zmw-width(container-medium);
  @include media('>medium') {
    padding-left: zmw-spacing(large);
    padding-right: zmw-spacing(large);
  }
}

@mixin content-container {
  body > & {
    @include container;
  }
}

@mixin container--wide {
  @include container;

  max-width: zmw-width(container-large);
}

@mixin content-container--wide {
  body > & {
    @include container--wide;
  }
}

@mixin container--bleed {
  margin-left: auto;
  margin-right: auto;
  max-width: zmw-width(container-max);
}
