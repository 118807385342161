.zmw-HeaderBlock {
  margin-bottom: zmw-spacing(xlarge);
}

.zmw-HeaderBlock_Image {
  @include container--bleed;

  width: 100%;
  height: auto;
  display: block;
}

.zmw-HeaderBlock_Body {
  @include container;
  padding-top: zmw-spacing(xlarge);
  margin-top: zmw-spacing(medium);

  @include media('>medium') {
    position: relative;
  }

  @include media('>medium') {
    .zmw-HeaderBlock_Body_Container {
      display: flex;

      .zmw-HeaderBlock_TextContainer {
        .zmw-HeaderBlock_TextContent {
          padding-left: 1.75rem;
        }
      }

      .zmw-HeaderBlock_Icon {
        min-width: 100px;
      }
    }
  }
}

.zmw-HeaderBlock:not(.zmw-HeaderBlock-withoutImage) {
  margin-bottom: zmw-spacing(xlarge);
}

.zmw-HeaderBlock:not(.zmw-HeaderBlock-withoutImage) .zmw-HeaderBlock_Body {
  @include media('>medium') {
    margin-top: -#{zmw-spacing(xxlarge)};
    background-color: zmw-color(base-grey);
  }
}

/*
 * 1 - Keep the SVG at an appropriate size
 */
.zmw-HeaderBlock_Icon {
  max-width: 100px; /* 1 */
  width: 100%;
  height: auto;
}

.zmw-HeaderBlock_Title {
  margin-top: zmw-spacing(xsmall);
}

.zmw-HeaderBlock_Content {
  margin-top: zmw-spacing(xsmall);
}

.zmw-HeaderBlock_Amount {
  color: zmw-color(brand);
}
