.dw-Filpper_Container {
  perspective: 1000;

  .dw-Filpper_Content {
    position: relative;
    transition: 0.6s;
    transform-style: preserve-3d;

    .dw-Filpper_Front {
      z-index: 2;
    }

    .dw-Filpper_Front,
    .dw-Filpper_Back {
      width: 100%;
      height: 140px;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      transition: 0.6s;
      transform-style: preserve-3d;
      transform: rotateY(0deg);
    }

    .dw-Filpper_Back {
      transform: rotateY(-180deg);
      background: #f8f8f8;
    }
  }
}
