.zmw-Story {
  @include content-container;

  margin-bottom: zmw-spacing(xlarge);
}

.zmw-Story-withImage {
  .zmw-Story_Container {
    background-color: transparent;
  }

  .zmw-Story_Image {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;

    @include media('>medium') {
      float: left;
      width: 60%;
      margin-left: 5%;
    }
  }

  .zmw-Story_Body {
    background-color: zmw-color(base);
    position: relative;
    z-index: 2;
    box-shadow: zmw-boxshadow(light);

    @include media('>medium') {
      float: right;
      width: 70%;
      margin-right: 5%;
      margin-top: -2em;
    }
  }
}

/*
 * 1 - Used to vertical align both elements
 */
.zmw-Story_Container {
  @include clearfix;

  background-color: zmw-color(base);
}

.zmw-Story_Body {
  @include block-spacing;

  img {
    width: 100%;
    height: auto;
    margin: 0 0 0.5em;
  }
}

/*
 * 1 - When both are present, we need a space between them.
 */
.zmw-Story_Body h2 + .zmw-Story_Content {
  margin-top: zmw-spacing(small); /* 1 */
}

.zmw-Story_Content {
  iframe {
    & + p {
      margin-top: 1em;
    }
  }
}

.zmw-Story .zmw-CallToAction_Link {
  @include cta-muted;

  margin-top: zmw-spacing(medium);
}
