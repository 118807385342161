.zmw-TextArea_Input {
  display: block;
  min-height: 10em;
  width: 100%;
  margin-top: zmw-spacing(xsmall);
  padding: zmw-spacing(xsmall) zmw-spacing(xxsmall);
  @include input-text-layout;
  @include input-text-border;
}

.zmw-TextArea--error .zmw-InputText_Input {
  border-color: zmw-color(error);
}
