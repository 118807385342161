/**
 * 1. Applying a proper box-model to the root element
 *    From: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 *    From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L11-L12
 * 3. Prevent any horizontal overflow
 */
html {
  box-sizing: border-box; /* 1 */
  overflow-x: hidden; /* 3 */
  text-size-adjust: 100%; /* 2 */
}

/**
 * Massive "barbarian" reset on all elements and pseudo-elements
 * 1. Inherit `box-sizing` from parent element, useful for third-party
 *    component built on another box-model
 * 2. No padding, no margin, no border
 */
*,
::after,
::before {
  border: 0; /* 2 */
  box-sizing: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 2 */
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L33-L47
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L77-L80
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L89-L91
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L162-L176
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L193-L195
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L252-L258
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Correct letter-spacing properties not being inherited.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  letter-spacing: inherit;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L290-L296
 * 1. Kept only `button` because other selectors are to be avoided
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button /* 1 */ {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L302-L305
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L311-L315
 * Remove inner padding and border in Firefox 4+.
 * 1. Custom addition to involve `select` as well
 */
select::-moz-focus-inner, /* 1 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L322-L324
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L356-L357
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: none; /* 1 */
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L367-L370
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Hack to remove yellow Chrome autofill:
 * http://webagility.com/posts/remove-forced-yellow-input-background-in-chrome
 */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L396-L398
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * From: https://github.com/necolas/normalize.css/blob/2bdda84272650aedfb45d8abe11a6d177933a803/normalize.css#L416-L419
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
