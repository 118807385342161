@mixin input-text-layout {
  width: 100%;
  padding: zmw-spacing(xsmall) 0;
  font-size: zmw-fontsize(base);
  font-weight: zmw-fontweight(medium);
  background-color: transparent;

  @include placeholder {
    color: zmw-color(base-grey, text-dimmed);
    font-weight: zmw-fontweight(light);
  }

  &:active,
  &:focus {
    border-color: inherit;
  }
}

@mixin input-text-border {
  border-bottom: 2px solid zmw-color(base-grey, text-default);
}
