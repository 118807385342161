@mixin zmw-button {
  background: zmw-color(brand);
  border: none;
  color: zmw-color(brand, text-default);
  cursor: pointer;
  padding: 0.6em 1.2em;
  border-radius: $zmw-button-radius;
  transition: background zmw-transition(base);
  border: 1px solid transparent;

  &:hover,
  &:focus {
    background-color: zmw-color(base);
    color: zmw-color(brand);
    border: 1px solid zmw-color(brand);
  }
}

/*
 * 1. Hack the focus style
 */
@mixin zmw-button-round {
  border-radius: 100%;
  width: 2.8em;
  height: 2.8em;
  padding: 0;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px zmw-color(highlight);
  }
}
