.zmw-CallToAction {
  @include content-container;
}

.zmw-Columns_Column > .zmw-CallToAction {
  @include block-spacing--columns;
}

.zmw-CallToAction_Link {
  @include cta-bold;

  @include media('>medium') {
    display: inline-block;
    width: auto;
    padding: 1em 1.5em;
  }

  .zmw-Columns & {
    width: 100%;
  }
}

.zmw-Quote .zmw-CallToAction_Link {
  @include cta-small;

  margin-top: zmw-spacing(small);

  @include media('>medium') {
    display: inline-block;
    width: auto;
  }
}
