.dw-DonationWidget {
  @include container--wide;

  padding: zmw-spacing(large);
  margin-bottom: zmw-spacing(xxxlarge);
  background: zmw-color(base);
}

.dw-DonationItemsIntro_Container {
  .dw-DonationItemsIntro_Title {
    @include as-h2-dark;

    & + .dw-DonationItemsIntro_Intro {
      margin-top: zmw-spacing(xsmall);
    }
  }
}

.dw-Progress_Container {
  background: zmw-color(accent);
  padding: zmw-spacing(small) zmw-spacing(medium);
  text-align: left;

  .dw-Progress_Title {
    @include as-h3-dark;

    color: zmw-color(base, text-default);
    text-transform: none;
  }

  .dw-Progress_Status {
    display: none;
  }

  .dw-Progress_Bar {
    display: none;
    margin: zmw-spacing(xxsmall) 0 0;
    border-radius: 0;

    .react-sweet-progress-line {
      border-radius: 0;
      background: zmw-color(base-beige, dark);

      .react-sweet-progress-line-inner {
        min-height: 15px;
        border-radius: 0;
        background-color: zmw-color(rational, more-contrast) !important;
      }

      .react-sweet-progress-line-inner-status-active {
        background-color: zmw-color(rational) !important;
        &::before {
          border-radius: 0;
        }
      }
    }

    .react-sweet-progress-symbol {
      font-weight: zmw-fontweight(light);
    }
    .react-sweet-progress-symbol svg path {
      fill: zmw-color(rational, more-contrast) !important;
    }
  }

  .dw-Progress_Total {
    padding: zmw-spacing(small) 0 0 0;
    color: zmw-color(base, text-default);

    .dw-Progress_Currency,
    .dw-Progress_Amount {
      font-weight: zmw-fontweight(medium);
    }
  }
}

.dw-DonationItems_DonationTypesContainer {
  margin-top: zmw-spacing(medium);

  .dw-GiftAmounts_Title {
    margin-top: zmw-spacing(small);
    @include as-h3;

    padding: 1.5rem zmw-spacing(xsmall) 0 zmw-spacing(xsmall);
  }

  .dw-GiftAmounts_Description {
    font-size: zmw-fontsize(small);
    color: zmw-color(base-grey, text-default);
  }

  /*
   * 1 - Fallback for calc
   */
  .dw-GiftAmount {
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-size: zmw-fontsize(medium);
    font-weight: zmw-fontweight(medium);
    border-radius: 0;
    display: block;
    padding: zmw-spacing(small) zmw-spacing(xsmall);
  }

  .dw-GiftAmount:nth-child(even) {
    float: right;
  }

  .dw-GiftAmount_Currency {
    font-size: zmw-fontsize(xsmall);
  }
}

.dw-DonationItemGroup_Title {
  @include as-h3;
}

.dw-DonationItemGroup_Container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 (0 - zmw-spacing(xsmall)) 0;

  .dw-DonationItem {
    width: 50%;
    max-width: 172px;
    min-width: 150px;
    margin: zmw-spacing(small) 0 0;

    /* temporary hideout */
    &:nth-child(n + 5) {
      display: none;
    }
  }
  @include media('>plus') {
    margin: 0 (0 - zmw-spacing(xsmall)) 0;
    flex-flow: row;

    .dw-DonationItem {
      flex: 1;
    }
  }
}

@include media('>large') {
  .dw-GiftAmounts_Title {
    display: none;
  }

  .dw-DonationWidget_DonationTypes {
    display: flex;
    margin: 0 (0 - zmw-spacing(medium)) 0;
  }

  .dw-DonationWidget_Options {
    width: 35%;
    padding: 0 zmw-spacing() 0 zmw-spacing(xsmall);
  }

  .dw-DonationItemGroup {
    margin: 0;
    width: 65%;
    padding: 0 zmw-spacing(xsmall) 0 zmw-spacing();
  }

  .dw-DonationItemGroup_Description {
    margin-top: 0.25em;
    font-size: zmw-fontsize(small);
  }
}
