/*
 * 1 - Remove the extra spacing when inside a ContentSection container
 */
@mixin block-spacing {
  padding: zmw-spacing(medium) zmw-spacing(small);

  @include media('>medium') {
    padding: zmw-spacing(xlarge) zmw-spacing(large);
  }

  @include media('>large') {
    padding: zmw-spacing(xlarge) zmw-spacing(xlarge);
  }

  .zmw-ContentSection & {
    padding: 0; /* 1 */
  }
}

@mixin block-spacing--columns {
  padding: zmw-spacing(xsmall) 0;

  @include media('>medium') {
    padding: zmw-spacing(small) 0;
  }

  @include media('>large') {
    padding: 0 zmw-spacing(xlarge);
  }

  .zmw-ContentSection & {
    padding: 0; /* 1 */
  }
}
