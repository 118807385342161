.zmw-QuestionGroup {
  @include content-container;

  margin: zmw-spacing(xxlarge) 0 zmw-spacing(large);
}

.zmw-QuestionGroup .zmw-CallToAction {
  text-align: right;
}

.zmw-QuestionGroup .zmw-CallToAction_Link {
  @include cta-muted;
}

.zmw-QuestionGroup_Label {
  font-size: zmw-fontsize(medium);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(base-grey, text-default);
  margin-bottom: zmw-spacing(large);
}
