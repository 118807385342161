$logo-height: 40px;
$logo-height-wide: 55px;

.zmw-RaiseNowIntro {
  @include content-container;

  background-color: zmw-color(base);
  display: none;
}

.js-on-checkout .zmw-RaiseNowIntro {
  display: block;
}

.zmw-RaiseNowIntro_Container {
  padding: zmw-spacing(small) 0;
}

.zmw-RaiseNowIntro_LogoLink {
  height: $logo-height;
  display: block;
  margin-bottom: zmw-spacing(small);

  @include media('>medium') {
    height: $logo-height-wide;
  }
}

.zmw-RaiseNowIntro_LogoLink .zmw-Logo {
  height: 100%;
}
