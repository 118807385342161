.dw-Finalize {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: zmw-color(base);
  width: 100%;
  box-shadow: zmw-boxshadow(light);
  z-index: 20;
  padding: zmw-spacing(xxsmall);

  @include media('>medium') {
    position: static;
    box-shadow: none;
    background-color: transparent;
    text-align: center;
    padding: 0;
    margin: zmw-spacing(medium) 0 0;
  }
}

.js-zmw-MainMenu--isOpen .dw-Finalize {
  display: none;
  @include media('>medium') {
    display: block;
  }
}

.dw-Finalize_Currency {
  color: zmw-color(brand);
  font-size: zmw-fontsize(xsmall);
  font-weight: zmw-fontweight(medium);
}

.dw-Finalize_Buttons {
  display: flex;
  flex-flow: row-reverse;
  border-top: solid 0.125rem zmw-color(base-grey, hex);

  .dw-Finalize_ButtonContent {
    width: 35%;

    &:first-child {
      width: 65%;
    }

    .dw-Finalize_Donate {
      width: 100%;
      padding: 1em 0;
      font-weight: zmw-fontweight(semi);
    }

    .dw-Finalize_Reset {
      width: 100%;
      height: 100%;
      padding: 1em 0;
      border-radius: 0;
      color: zmw-color(base, text-default);
      background-color: zmw-color(base-grey, least-contrast);

      &:hover,
      &:focus {
        background-color: zmw-color(base-grey, less-contrast);
      }
    }
  }
}
