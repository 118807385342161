.zmw-LanguageMenu {
  @include media('>large') {
    background-color: zmw-color(base);
  }
}

.zmw-LanguageMenu_Item {
  list-style-type: none;
}

.zmw-LanguageMenu_Item.language_short {
  display: inline-block;
}

.zmw-LanguageMenu_Link {
  font-weight: zmw-fontweight(light);
}
