.zmw-Form_Message {
  background-color: zmw-color(base-grey);
  border-color: darken(zmw-color(base-grey), 10%);
  border-width: 2px;
  border-style: solid;
  padding: zmw-spacing(small);
  border-radius: $zmw-button-radius;
}

.zmw-Form_Message--success {
  background-color: zmw-color(base);
  border-color: zmw-color(brand);
}

.zmw-Form_Message--error {
  background-color: transparent;
  border-color: zmw-color(error);
  color: zmw-color(error);
  margin-top: zmw-spacing(medium);
}
