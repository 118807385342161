.zmw-NewsletterForm {
  @include content-container;
  @include clearfix;

  margin-top: zmw-spacing(medium);
}

.zmw-NewsletterForm_Label {
  @include as-h3;
}

.zmw-NewsletterForm .zmw-InputText_Input {
  padding: 1em 0.6em;
  margin-top: zmw-spacing(xsmall);
  background-color: zmw-color(base);
  border: 2px solid zmw-color(base-grey, least-contrast);
  display: block;

  @include media('>large') {
    width: 50%;
  }

  .zmw-ContentSection-informational & {
    border-color: zmw-color(base-grey, text-dimmed);
  }
}

.zmw-NewsletterForm .zmw-InputText--error .zmw-InputText_Input {
  border: 2px solid zmw-color(error);
}

.zmw-NewsletterForm_Button {
  margin-top: zmw-spacing(xsmall);
  float: right;

  @include media ('>large') {
    float: none;
  }
}
