.zmw-Footer {
  @include clearfix;

  background-color: zmw-color(base);
  padding: zmw-spacing(xlarge) 0 0;
  margin-top: zmw-spacing(xxxlarge);
}

.zmw-Footer_Container {
  @include container--wide;

  .zmw-AsyncForm_Container {
    padding: 0;
  }
}

/*
 * 1. Override the default for columns
 */
.zmw-Footer .zmw-Columns_Column {
  padding: 0; /* 1 */
  margin-bottom: zmw-spacing(xxlarge);
}

.zmw-Footer .zmw-Columns_Column:not(:last-child) {
  @include media('>medium') {
    margin-right: zmw-spacing(xlarge);
  }

  @include media('>large') {
    margin-right: zmw-spacing(xxlarge);
  }
}

.zmw-Footer .zmw-CorporatePartnerGroup {
  margin-bottom: zmw-spacing(large);
}

.zmw-Footer .zmw-Columns_Column {
  @include clearfix;

  margin-left: 0;
}

.zmw-Footer_List {
  margin-bottom: zmw-spacing(xlarge);

  @include media('>medium') {
    float: left;
  }
}

.zmw-Footer_List:not(:last-child) {
  @include media('>medium') {
    margin-right: zmw-spacing(xlarge);
  }

  @include media('>large') {
    margin-right: zmw-spacing(xxlarge);
  }
}

.zmw-Footer_Label {
  @include as-h3;

  margin-bottom: zmw-spacing(small);
}

.zmw-Footer .zmw-LanguageMenu_Item {
  margin-bottom: zmw-spacing(small);
}

.zmw-Footer .zmw-AsyncForm {
  margin-bottom: zmw-spacing(large);
}

.zmw-Footer .zmw-NewsletterForm {
  margin-top: 0;
}

.zmw-Footer .zmw-InputText_Label {
  font-size: zmw-fontsize(base);
  font-weight: zmw-fontweight(light);
  color: zmw-color(base, text-default);
  text-transform: none;
}

.zmw-Footer .zmw-NewsletterForm .zmw-InputText_Input {
  @include media('>medium') {
    width: 80%;
  }
}

.zmw-Footer .zmw-NewsletterForm_Button {
  float: none;
}

.zmw-Footer .zmw-SocialsSharing {
  display: none;
  margin-bottom: zmw-spacing(small);

  @include media('>large') {
    display: block;
  }
}
