.zmw-ContentBlock {
  @include content-container;

  margin-bottom: zmw-spacing(xlarge);
}

.zmw-ContentBlock-withImage {
  @include clearfix;

  .zmw-ContentBlock_Image {
    @include media('>medium') {
      float: right;
      width: 65%;
      margin-bottom: 0;
    }
  }

  .zmw-ContentBlock_Body {
    @include media('>medium') {
      float: left;
      width: 75%;
      background-color: zmw-color(base-grey);
      margin-top: -2em;
      padding-top: zmw-spacing(medium);
      padding-right: zmw-spacing(medium);
    }
  }
}

.zmw-ContentBlock_Image {
  display: block;
  width: 100%;
  margin-bottom: zmw-spacing(small);
}

.zmw-ContentBlock_Headline {
  font-size: zmw-fontsize(xlarge);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(brand);
  line-height: 1.2;
}

/*
 * Add margin if headline exists
 */
.zmw-ContentBlock_Headline + .zmw-ContentBlock_Content {
  margin-top: zmw-spacing(xsmall);
}

.zmw-ContentBlock_Content > *:not(:first-child) {
  margin-top: zmw-spacing(medium);
}

.zmw-ContentBlock_Content ul {
  list-style-type: none;
  position: relative;
}

.zmw-ContentBlock_Content li:not(:first-child) {
  margin-top: zmw-spacing(small);
}

.zmw-ContentBlock_Content li {
  padding-left: zmw-spacing(medium);
}

/*
 * Used pseudo element to have different color for bullets
 */
.zmw-ContentBlock_Content li::before {
  content: "•";
  color: zmw-color(brand);
  left: 0;
  position: absolute;
}

.zmw-ContentBlock .zmw-CallToAction_Link {
  @include cta-muted;

  margin-top: zmw-spacing(medium);
}

.zmw-ContentBlock-informational .zmw-CallToAction {
  color: zmw-color(base-grey, text-default);
}

/*
 * Style for ContentSection-informational
 */
.zmw-ContentSection-informational .zmw-ContentBlock_Headline {
  color: zmw-color(base-beige, text-default);
  font-size: zmw-fontsize(medium);
  line-height: 1.2;
}

/*
 * Only add margin if headline exists
 */
.zmw-ContentSection-informational .zmw-ContentBlock_Headline + .zmw-ContentBlock_Content {
  margin-top: zmw-spacing(xxsmall);
}

.zmw-ContentSection-informational .zmw-ContentBlock_Content li::before {
  color: zmw-color(base-grey, text-default);
}

/*
 * 1. Reduce space between ContentBlock and BrandedCallToAction
 */
.zmw-ContentBlock + .zmw-BrandedCallToAction {
  margin-top: -(zmw-spacing(medium)); /* 1 */
}

/*
 * 1. Increase space between ContentBlocks
 */
.zmw-ContentBlock + .zmw-ContentBlock {
  margin-top: zmw-spacing(large); /* 1 */
}
.zmw-ContentBlock + .srk-SliderGallery {
  margin-top: zmw-spacing(large); /* 1 */
}
.srk-SliderGallery + .srk-SliderGallery {
  margin-top: zmw-spacing(large); /* 1 */
}
.srk-SliderGallery + .zmw-Story {
  margin-top: zmw-spacing(large); /* 1 */
}