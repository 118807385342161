.dw-Checkbox {
  font-size: zmw-fontsize(base);
  font-weight: zmw-fontweight(light);
  position: relative;
}

// Hide checkbox input
.dw-Checkbox_Input {
  position: absolute;
  opacity: 0;
}

.dw-Checkbox_Label {
  cursor: pointer;
}

/*
 * 1 - Vertical align label and before (border px)
 */
.dw-Checkbox_Label::before {
  content: '';
  display: inline-block;
  background-color: zmw-color(base, hex);
  border: 2px solid zmw-color(base-grey, text-dimmed);
  width: 1.3em;
  height: 1.3em;
  line-height: 1.1em;
  border-radius: 3px;
  margin-right: zmw-spacing(xsmall);
  vertical-align: middle;
  transform: translateY(-2px); /* 1 */
  text-align: center;
  font-weight: zmw-fontweight(medium);
  color: zmw-color(brand);
  font-size: zmw-fontsize(large);

  .dw-Checkbox_Input:checked + & {
    content: '×';
  }

  .dw-Checkbox_Input:focus + & {
    box-shadow:
      0 0 0 2px zmw-color(base),
      0 0 0 4px zmw-color(highlight);
  }
}
