.zmw-Sitemap_Item {
  list-style-type: none;
  margin-bottom: zmw-spacing(small);
}

.zmw-Sitemap_Link {
  font-weight: zmw-fontweight(light);
  color: zmw-color(base, text-default);

  &:hover {
    color: zmw-color(brand);
  }
}
