/*
 * 1 - Text has to be a bit shifted to the right
 */
.dw-Notice {
  margin-top: zmw-spacing(medium);
  padding: zmw-spacing(xsmall) 0 0;
  font-size: zmw-fontsize(small);
  font-weight: zmw-fontweight(light);
  color: zmw-color(base-grey, text-default);
  line-height: 1.5;
  border-top: 1px solid zmw-color(base-grey, text-dimmed);
}
