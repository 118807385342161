.zmw-ThankYou {
  padding-bottom: zmw-spacing(xxxlarge);
}

.zmw-ThankYou .zmw-ContentSection {
  @include container;
}

.zmw-ThankYou .zmw-CallToAction_Link {
  @include cta-muted;

  display: inline-block;
  text-align: left;
  margin-top: zmw-spacing(medium);

  &::after {
    position: static;
  }
}

.zmw-ThankYou_Feedback {
  @include container;
}
