.zmw-CorporatePartner {
  float: left;
  clear: both;
  margin-bottom: zmw-spacing(small);

  @include media('>medium') {
    &:nth-child(even) {
      clear: none;
    }
  }

  @include media('>large') {
    clear: none;
  }
}

/*
 * 1 - Remove extra space from img
 */
.zmw-CorporatePartner_Media {
  height: 60px;
  font-size: 0; /* 1 */
  margin-right: zmw-spacing(large);
}

.zmw-CorporatePartner_Logo {
  height: 100%;
  width: auto;
  max-width: 100%;
  vertical-align: middle;
}

.zmw-CorporatePartner_Text {
  padding-top: zmw-spacing(small);
}

.zmw-CorporatePartner_LinkLabel {
  padding-top: zmw-spacing(small);
  font-weight: normal;
}
