/// Micro clearfix hack.
/// @group layout
@mixin clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
    clear: both;
  }
}

@mixin clearfix--before {
  &::before {
    content: ' ';
    display: table;
    clear: both;
  }
}

@mixin clearfix--after {
  &::after {
    content: ' ';
    display: table;
    clear: both;
  }
}
