/*
 * 1 - Hide everywhere but on thank you page. Using display:none would prevent
 *     share links from being rendered properly on some browsers
 */
body:not(.js-on-thank-you) .zmw-ThankYouSharings {
  @include visually-hidden; /* 1 */
}

.zmw-ThankYouSharings_Label {
  @include as-h3;

  margin-bottom: zmw-spacing(medium);
}

