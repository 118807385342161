.dw-CustomAmount {
  display: flex;
  width: 100%;
  margin: 0.7rem 0 0;
  cursor: pointer;
  justify-content: space-between;
  padding: zmw-spacing(xsmall);
  transition: all zmw-transition(base);

  .dw-CustomAmount_Content {
    width: 100%;
    display: flex;
    background-color: zmw-color(brand, text-default);
    box-shadow: zmw-boxshadow(medium);

    .dw-CustomAmount_Row {
      flex: 1;
      position: relative;
    }
  }
}

/*
 * 1 - Mobile Safari adds a border radius by default
 * 2 - Visual adjustment
 */
.dw-CustomAmount_Input {
  color: zmw-color(brand);
  font-size: zmw-fontsize(medium);
  font-weight: zmw-fontweight(medium);
  border-bottom: 2px solid zmw-color(brand);
  border-radius: 0; /* 1 */
  padding: 0 6px; /* 2 */
  width: 100%;
  height: 100%;

  @include placeholder {
    color: zmw-color(base-grey, text-default);
    font-size: zmw-fontsize(base);
  }
}

.dw-GiftAmounts {
  margin: 0 (0 - zmw-spacing(xsmall));

  .dw-GiftAmounts_Container {
    .dw-GiftAmounts_AmountContainer {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .dw-GiftAmounts_ButtonContainer {
        flex: 1;
        display: flex;

        .dw-GiftAmounts_ButtonContent {
          width: 100%;
          height: 100%;
          padding: zmw-spacing(small) zmw-spacing(xsmall) 0 zmw-spacing(xsmall);
        }
      }
    }
  }

  @include media('>large') {
    .dw-GiftAmounts_ButtonContainer {
      min-width: 50%;
    }
  }
}

.dw-CustomAmount_Currency {
  position: absolute;
  right: zmw-spacing(small);
  top: 50%;
  transform: translateY(-50%);
  font-size: zmw-fontsize(medium);
  font-weight: zmw-fontweight(medium);
  color: zmw-color(base-grey, text-dimmed);
}

/*
 * 1 - TODO: Consider if we will use them as svg fallback
 */
.dw-CustomAmount_Add {
  font-size: 25px; /* 1 */
  color: zmw-color(base); /* 1 */
  background-color: zmw-color(brand);
  font-weight: zmw-fontweight(medium);
  padding: 9px 12px;
  transition: all zmw-transition(base);
  border-radius: 0;

  &:focus,
  &:active,
  &:hover {
    color: zmw-color(base);
    border-color: transparent;
    background-color: zmw-color(base-grey, text-dimmed);
  }
}

.dw-CustomAmount .dw-Button-disabled {
  box-shadow: none;
}
