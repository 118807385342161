/*
 * 1 - space for the donation widget bottom bar
 * 2 - space for the mainMenu
 * 3 - space for the mainMenu special case where menu breaks onto 2 lines
 */
body {
  background-color: zmw-color(base-grey);
  padding-bottom: 52px; /* 1 */
  padding-top: 58px; /* 2 */
  @include media('>medium') {
    padding-bottom: 0;
    padding-top: 60px; /* 2 */
  }
  @include media('>large') {
    padding-top: 80px; /* 2 */
  }
  @include media('>large', '<=1058px') { /* 3 */
    padding-top: 120px; /* 2 */
  }
}

:focus {
  outline: 2px solid zmw-color(highlight);
  outline-offset: 2px;
}
