.zmw-Form {
  @include content-container;
}

.zmw-Form .zmw-ErrorMessage {
  margin-top: zmw-spacing(xsmall);
}

.zmw-Form .zmw-HiddenFields {
  @include visually-hidden;
}

/*
 * 1. Disable the default border-radius for Mobile Safari
 */
.zmw-InputText_Input {
  border-radius: 0; /* 1 */
}
