.zmw-TimelineItem {
  @include content-container;

  text-align: center;
  display: block;
  padding-top: zmw-spacing(medium);
  padding-bottom: zmw-spacing(medium);
  background-color: zmw-color(base-grey);
  margin-bottom: zmw-spacing(xlarge);
}

.zmw-TimelineItem_Date {
  @include as-h3;

  margin-bottom: zmw-spacing(xsmall);
}

.zmw-TimelineItem_Content {
  font-size: zmw-fontsize(medium);
  font-weight: zmw-fontweight(medium);
}
