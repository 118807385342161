.zmw-CorporatePartnerGroup {
  @include content-container;
}

.zmw-CorporatePartnerGroup_Label {
  @include as-h3;
}

.zmw-CorporatePartnerGroup_Container:not(.zmw-CorporatePartnerGroup_Extended) {
  @include clearfix;

  margin-top: zmw-spacing(small);
}

.zmw-CorporatePartnerGroup_Extended {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: zmw-spacing(large);
}

/*
 * 1 - IE11 fallback -> https://github.com/philipwalton/flexbugs/issues/3
 * 2 - 1rem equals have zmw-spacing(large), used real value because calc can not convert it
 */
.zmw-CorporatePartnerGroup_Extended > a {
  display: block;
  background: zmw-color(base);
  height: auto;
  padding: zmw-spacing(large);
  margin-bottom: zmw-spacing(large);
  font-size: zmw-fontsize(small);
  font-weight: lighter;

  @include media('>large') {
    width: calc(50% - 1rem); /* 1 */
    flex: 0 1 calc(50% - 1rem); /* 2 */
  }

  &:hover .zmw-CorporatePartner_LinkLabel {
    color: zmw-color(brand);
  }
}
