@mixin fade-in {
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  visibility: visible;
}

@mixin flip {
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  visibility: visible;
}
